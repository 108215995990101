import { graphql, navigate, PageProps } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useEffect, useMemo, useState } from "react";

import Button from "../../../../../atoms/button/Button";
import DogBowlsIllustration from "../../../../../images/pages/bundles/dog-bowls.svg";
import Loader from "../../../../../molecules/loader/Loader";
import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import { BundleId } from "../../../../../organisms/bundle/domain/bundle";
import {
  useBundle,
  useSetBundleId,
  useSwitchBundleToMonthly,
} from "../../../../../organisms/bundle/use-cases/bundle-use-cases";
import Footer from "../../../../../organisms/footer/AlternativeFooter";
import Meta from "../../../../../organisms/meta/meta";
import Navigation from "../../../../../organisms/navigation/Funnel";
import PageWrapper from "../../../../../organisms/page-wrapper/PageWrapper";
import { States } from "../../../../../settings/state";
import * as styles from "../../../../../templates/SwitchToMonthly.module.scss";
import { Events, track } from "../../../../../utils/analytics";
import { formatCurrency } from "../../../../../utils/currency";
import { handleSwitchToMonthlyErrors } from "../../../../../utils/error-utils/catch-error-handlers";

const Page = ({ params }: PageProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const setBundleId = useSetBundleId();
  const bundleId = useMemo((): BundleId => {
    return { petKbKey: params.petKbKey, policyId: params.policyId };
  }, [params.petKbKey, params.policyId]);
  const bundle = useBundle();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const title = t("bundle.switch_to_monthly.title");
  const switchToMonthly = useSwitchBundleToMonthly();
  const handleCtaClick = () => {
    setIsSubmitting(true);
    switchToMonthly(bundleId)
      .then((isSuccessful: boolean) => (isSuccessful ? navigate(`success/`) : navigate(`error/`)))
      .catch((err: Error) => {
        handleSwitchToMonthlyErrors(err, "petKbKey/policyId/switch-to-monthly");
      });
  };
  const bundleIsLoading = bundle === States.LOADING_STATE;
  const bundleIsLoaded = !bundleIsLoading && !!bundle;
  const shouldNavigateToError =
    bundleIsLoaded && (!bundle.renewable || bundle.paymentIntervalMonths === 1);

  useEffect(() => {
    bundleIsLoading && track(Events.SWITCH_TO_MONTHLY_LOADER_VIEWED_BROWSER);
    bundleIsLoaded && track(Events.SWITCH_TO_MONTHLY_VIEWED_BROWSER);
  }, [bundleIsLoaded, bundleIsLoading]);

  useEffect(() => {
    setBundleId(bundleId);
  }, [bundleId, setBundleId]);

  if (shouldNavigateToError) {
    void navigate(`error/${bundle.paymentIntervalMonths === 1 ? "?is_monthly_payment=1" : ""}`);

    return <></>;
  }

  return (
    <PageWrapper>
      <Meta
        title={title}
        lang={i18n.language}
        bodyClass={styles.switchToMonthly}
        description={undefined}
        alternateVersions={false}
        slug={undefined}
        disableTranslations={true}
      />
      <Navigation email={bundleIsLoaded ? bundle?.email : undefined} />
      <main id="main" className="or-main">
        <div className={styles.wrapper}>
          {bundleIsLoading && <Loader title={t("bundle.switch_to_monthly.loading")} />}
          {bundleIsLoaded && (
            <>
              <DogBowlsIllustration
                className={styles.illustration}
                aria-hidden="true"
                focusable="false"
              />
              <SectionTitle
                adoptionClassName={styles.header}
                title={title}
                subtitle={t("bundle.switch_to_monthly.subtitle")}
              />
              <Button
                type={"button"}
                onClick={handleCtaClick}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {t("bundle.switch_to_monthly.cta", {
                  amount: formatCurrency(bundle.monthlyPremium, "es", "EUR"),
                })}
              </Button>
              <p className={styles.legal}>
                <small>{t("bundle.switch_to_monthly.disclaimer")}</small>
              </p>
            </>
          )}
        </div>
      </main>

      <Footer />
    </PageWrapper>
  );
};

export default Page;

export const query = graphql`
  query SwitchToMonthlyQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
